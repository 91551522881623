<template>
	<a
		v-if="item.title"
		:title="removeBoldTags(item?.title, '')"
		class="search-suggest__item-link"
		:href="url"
		@click="handleClick"
	>
		<li
			class="search-suggest__item-header search-suggest__item"
			aria-hidden="true"
		>
			<div>
				<BaseIcon
					:name="getImagePath(item?.type)"
					class="search-suggest__icons"
				/>
			</div>
			<div
				class="search-suggest__text-container"
			>
				<span
					class="search-suggest__title-word"
					v-html="item.title"
				>
				</span>
				<div class="search-suggest__title-word--additional">
					<small
						v-if="item.subtitle"
					>{{ removeBoldTags(item?.subtitle, ' ') }}</small>
				</div>
			</div>
		</li>
	</a>
</template>

<script setup lang="ts">
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

const getImagePath = (type: string): string => ({
	countries: 'location',
	regions: 'location',
	cities: 'location',
	hotels: 'home',
	shops: 'store',
	pages: 'pages',
	topicpages: 'pages',
	regiongroups: 'location',
	countryhotels: 'location',
	regionhotels: 'location',
	cityhotels: 'location',
} as {[key :string]: string})[type];

interface Props {
	url: string,
	item: {
		type: string;
		title: string;
		url: string;
		subtitle: string;
	}
}
const emit = defineEmits(['SearchSuggestItem:Loading']);

withDefaults(defineProps<Props>(), {});

const handleClick = (event: MouseEvent | KeyboardEvent) => {
	if (!event.ctrlKey && !event.altKey && !event.shiftKey && !event.metaKey) {
		emit('SearchSuggestItem:Loading');
	}
};

const removeBoldTags = (item: string, replaceValue: string): string => item.replace(/<b>/g, replaceValue).replace(/<\/b>/g, replaceValue);

</script>

<style lang="scss" scoped>
:deep(b) {
	color: $color-primary;
	font-weight: $extra-bold;
}

@mixin active-focus-styles {
	background: $color-destion-field-active-background;
	color: $color-white;

	.search-suggest__title-word,
	.search-suggest__icons,
	.search-suggest__title-word--additional, {
		color: $color-white !important;
		fill: $color-white !important;

		:deep(.icon-location),
		:deep(.icon-pages),
		:deep(.icon-home),
		:deep(.icon-store) {
			stroke: $color-white;
			fill: none;

			&.icon-home,
			&.icon-store {
				fill: none !important;
			}
		}
	}
}

.search-suggest {
	&__item-header {
		margin: 0 0 1rem;
		padding-left: 3rem;
		font-size: 1.6rem;
		font-weight: bold;
		text-align: left;
	}

	&__item {
		width: 100%;
		padding: 1.5rem 1.5rem 1.5rem 1rem;
		border: none;
		background: none;
		color: $color-text-l24;
		font-size: 1.6rem;
		font-weight: 600;
		text-align: left;
		text-decoration: none;
		display: flex;

		&-link {
			text-decoration: none;
			height: 7rem;
			display: block;
		}

		&:hover {
			background: $color-primary-l6;
			color: $color-text-l24;
		}

		&:active,
		&:focus {
			@include active-focus-styles;

			& :deep(b) {
				color: $color-white;
			}
		}
	}

	@media (min-width: $breakpoint-medium) {
		.is-active {
			.search-suggest__item {
				@include active-focus-styles;

				& :deep(b) {
					color: $color-white;
				}
			}
		}
	}

	&__icons {
		width: 2.4em;
		height: 2.4rem;
		margin-right: 0.5rem;
		margin-top: 0.5rem;
	}

	&__text-container {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__title-word {
		color: $color-black;

		&--additional {
			display: flex;
			font-size: 1.2rem;
			color: $color-placeholder-text;
			font-weight: $font-weight-light;
			min-height: 1.7rem;

			& small {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}

</style>
