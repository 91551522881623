<template>
	<div
		class="search-suggest"
		@focus.capture="onFocus"
	>
		<DropdownField
			ref="dropdown"
			v-model="term"
			tabindex="-1"
			class="search-suggest__dropdown"
			:readonly="false"
			:manual="toggleDropdown"
			:close-on-esc="false"
			:show-footer="false"
			:show-toggle-icon="false"
			:placeholder="placeholderText"
			:icon="icon"
			:prevent-mousedown="false"
			:allow-clear="true"
			:is-focused="focus"
			:custom-clear-icon="customClearIcon"
			:class="{'search-suggest__dropdown-open' : toggleDropdown}"
			@DropdownField:FieldClick="onFieldClick"
			@DropdownField:OutsideClick="onOutsideClick"
			@DropdownField:Focus="onFocus"
			@DropdownField:Blur="() => focus = false"
			@DropdownField:Clear="clearSuggestAndClose"
			@DropdownField:Navigate="onNavigate"
		>
			<div
				ref="itemListContainer"
				class="search-suggest__list"
				role="group"
				aria-label="Ergebnisse"
				tabindex="-1"
				@scroll="onScrollContainter"
			>
				<!-- Loading -->
				<div
					v-if="loading"
					class="search-suggest__load-box"
				>
					<Loading
						size="small"
						class="search-suggest__loader"
					/>
				</div>

				<!-- Error -->
				<div
					v-else-if="error"
					class="search-suggest__helper"
				>
					<p>Der Server ist im Augenblick nicht erreichbar.</p>
					Bitte versuchen Sie es in Kürze erneut.
				</div>

				<!-- Empty result -->
				<div
					v-else-if="minCharCount && !searchSuggests.length "
					class="search-suggest__helper"
				>
					Keine Ergebnisse gefunden.
				</div>

				<!-- Result list -->
				<ul
					v-else
					class="search-suggest__item-list"
					:class="{'search-suggest__item-list--loading': loadingOnClick}"
				>
					<div
						v-if="loadingOnClick"
						class="search-suggest__load-box-suggest"
					>
						<Loading
							size="small"
							class="search-suggest__loader"
						/>
					</div>

					<!-- Result item -->
					<SerachSuggestItem
						v-for="(item, key) in searchSuggests"
						:key="key"
						ref="searchSuggestItemList"
						:class="{'is-active': key === activeIndex}"
						:url="buildUrl(item.url)"
						:item="item"
						@SearchSuggestItem:Loading="loadingOnClick = true"
					/>
				</ul>
			</div>
		</DropdownField>
	</div>
</template>

<script lang="ts" setup>

import { debounce } from '@utils/utils';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import {
	computed, onBeforeUnmount, onMounted, ref, nextTick, onBeforeMount, watch,
} from 'vue';
import { useStore } from '@/components/common/store';
import SerachSuggestItem from '@lmt-rpb/SearchSuggestItem/SearchSuggestItem.vue';
import * as searchSuggestService from '@services/searchSuggestService';
import { SuggestionElements } from '@/interfaces/api/v1-search-suggest';

const store = useStore();

interface Props {
	inline?: boolean,
	icon?: string,
	customClearIcon?: string
}

const props = withDefaults(defineProps<Props>(), {
	inline: false,
	icon: '',
	customClearIcon: undefined
});

const searchSuggestItemList = ref<InstanceType<typeof SerachSuggestItem>[]>([]);

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const formField = ref<InstanceType<typeof FormField> | null>(null);

const term = ref<string>('');

const isMinTablet = computed((): boolean => store.state.config.isMinTablet);

const isMobile = computed((): boolean => store.state.config.isMobile);

const toggleDropdown = ref<boolean | undefined>(props.inline);
const emptyState = [{
	type: '', title: '', url: '', subtitle: ''
}];

const headerHamburgerBtn = ref<HTMLElement | null>(null);

const searchSuggests = ref<SuggestionElements[]>(emptyState);

const loading = ref(false);

const loadingOnClick = ref(false);

const error = ref<boolean>(false);

const activeIndex = ref(0);

const focus = ref(false);

const itemListContainer = ref<HTMLElement>();

const input = ref<HTMLInputElement>();

const placeholderText = computed((): string => (!focus.value ? 'Suchen' : ''));

const baseUrl = computed((): string => store.state.config.baseUrl);

const buildUrl = (urlParams: string) => `${baseUrl.value}${urlParams}?term=${encodeURIComponent(term.value)}`;

const minCharCount = computed((): boolean => !!term.value && term.value.length >= 2);

const onFocus = (): void => {
	focus.value = true;
	input.value?.focus();
};

const onscroll = () => {
	if (window.scrollY >= 240 && toggleDropdown.value && isMinTablet.value) {
		toggleDropdown.value = false;
		input.value?.blur();
	}
};

const onScrollContainter = () => {
	if (!isMobile.value && searchSuggests.value?.length <= 3) return;

	input.value?.blur();
};

const search = async() => {
	loading.value = true;
	try {
		searchSuggests.value = await searchSuggestService.get(term.value);
	} catch (err) {
		console.error(err);
		error.value = true;
	} finally {
		loading.value = false;
	}
};

const bodyElem = computed((): HTMLElement => document && document.body);

watch(() => toggleDropdown.value, () => {
	// needed for ipads to be under the search
	bodyElem.value.classList.toggle('hidden-main-menu', toggleDropdown.value);
});

const clearSuggestAndClose = (): void => {
	term.value = '';
	searchSuggests.value = [];
	toggleDropdown.value = false;
};

const onTermChange = (): void => {
	error.value = false;
	if (!minCharCount.value) {
		searchSuggests.value = emptyState;
		toggleDropdown.value = false;
	} else {
		toggleDropdown.value = true;
		search();
	}
};

const onKeydown = (e: KeyboardEvent): void => {
	if (toggleDropdown.value && e.key === 'Enter') {
		const activeItem = searchSuggestItemList.value[activeIndex.value];
		if (activeItem && activeItem.$el) {
			(activeItem.$el as HTMLElement).click();
		}
	} else if (['Escape'].indexOf(e.key) === 0) {
		toggleDropdown.value = false;
	} else if (e.key === 'Tab' && !e.shiftKey && toggleDropdown.value) {
		onNavigate(1);
	} else if (e.shiftKey && e.key === 'Tab') {
		onNavigate(-1);
	}
};

const onNavigate = (direction: number) => {
	nextTick(() => {
		const list = itemListContainer.value as HTMLElement;

		if (list && searchSuggestItemList.value.length) {
			const itemsArr = searchSuggestItemList.value;
			activeIndex.value = Math.min(Math.max(0, activeIndex.value + direction), itemsArr.length - 1);
			const activeItem = itemsArr[activeIndex.value];
			activeItem.$el.focus();

			const top = activeItem.offsetTop;
			(list as HTMLElement).scrollTo({ top, behavior: 'smooth' });
		}
	});
};

const onInput = (): void => {
	// Important: In Android, we need to handle insertCompositionText for input events, as keyboards send this instead of insertText.
	if (input.value?.value && input.value?.value !== '') {
		activeIndex.value = 0;
		term.value = input.value.value;
	}
	onTermChange();
};

const onFieldClick = (clickOnField: boolean) => {
	if (clickOnField && !toggleDropdown.value && term.value && searchSuggests.value.length) {
		toggleDropdown.value = true;
	}
};

const onOutsideClick = (): void => {
	toggleDropdown.value = false;
};

const removeExistingSpinner = (): void => {
	toggleDropdown.value = false;
	loadingOnClick.value = false;
};

onBeforeMount(() => {
	window.addEventListener('pageshow', removeExistingSpinner);
});

onMounted((): void => {
	formField.value = dropdown.value?.field as any;
	input.value = formField.value?.input as HTMLInputElement;
	headerHamburgerBtn.value = document.querySelector('.header-iconbar__entry-menu');
	headerHamburgerBtn.value?.addEventListener('click', onOutsideClick);

	document.addEventListener('keydown', onKeydown);
	input.value?.addEventListener('input', debounce(onInput, 400));
	document.addEventListener('scroll', onscroll);
});

onBeforeUnmount((): void => {
	window.removeEventListener('pageshow', removeExistingSpinner);
	document.removeEventListener('keydown', onKeydown);
	headerHamburgerBtn.value?.removeEventListener('click', onOutsideClick);
	input.value?.removeEventListener('input', onInput);
	document.removeEventListener('scroll', onscroll);
});

defineExpose({
	input,
	toggleDropdown,
	dropdown,
	clearSuggestAndClose,
	term,
	search,
	searchSuggests
});

</script>

<style lang="scss" scoped>
:deep(.form-field__icon) {
	margin-left: 0.5rem;
}

:deep(.form-field) {
	@media (min-width: $breakpoint-small) {
		height: 5.6rem;
	}
}

:deep(.form-field__input) {
	&::placeholder {
		font-weight: 600;
	}
}

:deep(.dropdown__box) {
	margin-top: 0;
	left: 50%;
	width: 100%;

	@media (min-width: $breakpoint-small) {
		margin-top: 1rem;
	}

	@include customScrollbar( $color-primary, true, 0.8rem);
}

:deep(.dropdown__inner) {
	overflow: auto;
}

@media (min-width: $breakpoint-small) {
	:deep(.dropdown.is-open .dropdown__box) {
		width: 39rem;
		min-width: 39rem;
		left: 72.1%;
	}

	:deep(.dropdown__inner) {
		border-top-right-radius: 1rem;
	}
}

@media (min-width: $breakpoint-medium) {
	:deep(.dropdown__inner) {
		border-top-right-radius: 0;
	}

	:deep(.dropdown.is-open .dropdown__box) {
		width: 100%;
		left: 50%;
	}
}

:deep(.dropdown .dropdown__container) {
	padding-bottom: 0.4rem;
}

@media (min-width: $breakpoint-extralarge) {
	:deep(.dropdown.is-open .dropdown__box) {
		height: auto;
		max-height: 75rem;
	}

	:deep(.dropdown .dropdown__container) {
		height: auto;
		max-height: 75rem;
	}
}

.search-suggest {
	&__title-word--match {
		color: $color-primary;
		font-weight: $extra-bold;
	}

	&__title-word {
		color: $color-black;

		&--additional {
			display: flex;
			font-size: 1.2rem;
			color: $color-placeholder-text;
			font-weight: $font-weight-light;
		}
	}

	&__helper {
		padding: 1rem 2rem 2rem;
		font-size: 1.6rem;
		font-weight: $bold-default;

		@media (min-width: $breakpoint-small) {
			padding: 1rem 5rem 2rem;
		}
	}

	&__load-box {
		position: relative;
		padding: 3.75rem 2rem;
	}

	&__load-box-suggest {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-75%, -50%, 0);
	}

	&__list {
		overflow: auto;
		min-height: calc(100% + 0.5rem);
	}

	&__item {
		padding-left: 0.2rem;

		&-list {
			margin: 0;
			padding: 0;
			list-style: none;

			&--loading {
				opacity: 0.4;
			}
		}
	}

	&__empty-item {
		padding: 1.5rem 1.5rem 1.5rem 4.5rem;
	}

	// dropdown style
	@media (min-width: $breakpoint-small) {
		.search-suggest__dropdown {
			width: 27rem;
			margin: 0 auto;

			.dropdown-field__field::after {
				display: none;
			}

			.dropdown-box {
				margin-top: 0;
				border-top: 0;
			}
		}
	}

	@media (min-width: $breakpoint-medium) {
		.search-suggest__dropdown {
			width: 39rem;
		}
	}
}
</style>
